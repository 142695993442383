<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Profile</div>
      <div class="card-body">
        <v-form ref="form" lazy-validation v-model="valid">
          <div class="row" v-if="edit_profile">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="edit_profile.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="edit_profile.email" outlined readonly></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row">
                <div class="col-lg-3 pt-0">
                  <label for="inputText3" class="col-form-label">Code</label>
                  <v-autocomplete v-model="edit_profile.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                </div>
                <div class="col-lg-9 pt-0">
                  <label for="inputText3" class="col-form-label">Phone</label>
                  <v-text-field type="text" v-model="edit_profile.mobile" outlined readonly></v-text-field>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Gender</label>
              <v-autocomplete  v-model="edit_profile.gender" :items="gender" item-text="text" item-value="value" outlined></v-autocomplete>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Age</label>
              <v-text-field type="text" v-model="edit_profile.age"  outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Parent Name</label>
              <v-text-field type="text" v-model="edit_profile.parent_name" outlined></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Parent Email</label>
              <v-text-field type="text" v-model="edit_profile.parent_gmail" outlined></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Country<span class="red--text"><strong>* </strong></span></label>
                <v-autocomplete v-model="edit_profile.country_id" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" v-on:click="getRegions()" searchable outlined></v-autocomplete>
            </div>                  
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Region</label>
                <v-autocomplete v-model="edit_profile.region_id" :items="regions" item-text="text" item-value="value" searchable outlined></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row">
                <div class="col-lg-3 pt-0">
                  <label for="inputText3" class="col-form-label">Code</label>
                  <v-autocomplete v-model="edit_profile.whatsapp_code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                </div>
                <div class="col-lg-9 pt-0">
                  <label for="inputText3" class="col-form-label">Phone</label>
                  <v-text-field type="text" v-model="edit_profile.whatsapp_no" outlined readonly></v-text-field>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Course</label>
                <v-autocomplete  v-model="edit_profile.course_id" :items="courses" item-text="text" item-value="value" outlined></v-autocomplete>
            </div>  
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Course enrollment details for which you are registering ( time/days/time zone)<span class="red--text"><strong>* </strong></span></label>
              <v-textarea type="text" v-model="edit_profile.enrollment_info" :rules="[v => !!v || 'Requird']"   outlined></v-textarea>            
            </div>  
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Address</label>
              <v-textarea type="text" v-model="edit_profile.address" outlined></v-textarea>            
            </div>  
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Enter the Fees for a month you are supposed to pay or already paid for the course:</label>
              <v-text-field type="text" v-model="edit_profile.fees" outlined></v-text-field>
            </div>  
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
              <p>Payment done via  <span style="color:red">*</span></p>
              <v-radio-group v-model="edit_profile.payment" :rules="[v => !!v || 'Select payment method']">
                <v-radio label="PayPal Directly (fromyourtutor@gmail.com)" :value="'1'"></v-radio>
                <v-radio label="Fromyourtutor's Website (https://fromyourtutor.com/payment)" :value="'2'"></v-radio>
                <v-radio label="Bank Account (contact admin@fromyourtutor.com)" :value="'3'"></v-radio>
              </v-radio-group>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
              <label for="formFile" class="form-label">Fees screenshot here <span style="color:red">*</span></label>
              <div class="payment">
                <img :src="student.screenshot" alt="Payment screenshot" width="120" v-on:click="openImage">
                <!-- <i class="fa fa-eye"></i> -->
              </div>
              <!-- <v-file-input accept="image/*" v-model="student.uploaded_screenshot" :rules="[v => !!v || 'Upload screenshot']" label="" placeholder="Upload"></v-file-input> -->
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <a href="" v-on:click.prevent="toEditProfile" class="btn btn-success btn-block text-white w-100">Save Changes</a>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to Update these student details?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-danger" v-if="loading">...</button>
            <button type="button" class="btn btn-danger" v-if="!loading" v-on:click="confirmUpdate">Save</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="open-image" ref="image-modal" title="Payment Screenshot" hide-footer centered>
      <div class="container">
        <img :src="student.screenshot" alt="payment-zoom-image">
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'Student-Profile',
  props:["student"],
  data(){
    return {
      edit_profile:{
        name:null,
        mobile:null,
        country_code:null,
        profile_pic:null,
        country_id:null,
        region_id:null,
        course_id:null,
      },
      countries:[],
      codes:[],
      courses:[],
      regions:[],
      valid:false,
      reset_valid:false,
      loading:false,
      profile_pic:null,
      gender:[
        {value:1, text:'Male'},
        {value:2, text:'Female'},
        {value:3, text:'Other'}
      ],
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.reset_password.new_password == v || 'Password not match'
      ],
    }
  },
  beforeMount(){
    this.getCountries();
    this.getCourses();
    if(this.student.country_id){
      this.listRegions(this.student.country_id);
    }
    this.student.user.code =  this.student.user.code?parseInt(this.student.user.code):null;
    this.student.code =  this.student.code?parseInt(this.student.code):null;

    this.edit_profile.name = (this.student.name)?this.student.name:null;
    this.edit_profile.email = (this.student.user.email)?this.student.user.email:null;
    this.edit_profile.code = (this.student.user.code)?parseInt(this.student.user.code):null;
    this.edit_profile.mobile = (this.student.user.mobile)?this.student.user.mobile:null;
    this.edit_profile.whatsapp_code = (this.student.code)?parseInt(this.student.code):null;
    this.edit_profile.whatsapp_no = (this.student.whatsapp_no)?this.student.whatsapp_no:null;
    this.edit_profile.country_id = (this.student.country_id)?this.student.country_id:null;
    this.edit_profile.course_id = (this.student.course_id)?this.student.course_id:null;
    this.edit_profile.region_id = (this.student.region_id)?this.student.region_id:null;
    this.edit_profile.payment = (this.student.payment)?this.student.payment:null;
    this.edit_profile.fees = (this.student.fees)?this.student.fees:null;
    this.edit_profile.parent_name = (this.student.parent_name)?this.student.parent_name:null;
    this.edit_profile.parent_gmail = (this.student.parent_gmail)?this.student.parent_gmail:null;
    this.edit_profile.age = (this.student.age)?this.student.age:null;
    this.edit_profile.gender = (this.student.gender)?this.student.gender:null;
    this.edit_profile.address = (this.student.address)?this.student.address:null;
    this.edit_profile.enrollment_info = (this.student.enrollment_info)?this.student.enrollment_info:null;
    this.edit_profile.screenshot = (this.student.screenshot)?this.student.screenshot:null;
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    openImage(){
      this.$refs['image-modal'].show();
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode});
        })
      })
    },
    getRegions(){
      this.countries.forEach(country => {
        if(country.value == this.edit_profile.country_id){
          this.edit_profile.country_code = country.code;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(course => {
          this.courses.push({value:course.subject_id, text:course.subject_name});
        })
      })
    },
    setCountry(){
      this.countries.forEach(country => {
        if(country.code == this.edit_profile.country_code){
          this.edit_profile.country_id = country.value;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.edit_profile.code){
          this.profile.country = {value:country.value, text:country.text};
        }
      })
      this.edit_profile.country_id = this.edit_profile.country.value;
      this.listRegions(this.edit_profile.country.value);
    },
    toEditProfile(e){
      e.preventDefault();
      this.loading = false;
      this.valid = this.$refs.form.validate();
      if(this.valid){
        this.$refs['confirm-modal'].show();
      }
    },
    confirmUpdate(e){
      e.preventDefault();
      this.loading = true;
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/student/form/'+this.student.student_id,this.edit_profile,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == true){
          this.$refs['confirm-modal'].hide();
          var cookie_data = this.$cookies.get('tution-student');
          cookie_data.name = this.edit_profile.name;
          this.$cookies.set("tution-student", cookie_data);
          location.reload();
        }
      });
    }
  }
}
</script>

<style scoped>
/* .payment img{
  transition: transform .2s;
}
.payment img:hover{
  transform: scale(1.5);
} */
</style>
